import React from 'react';
import AppRoutes from './Routes'; // Importer le composant AppRoutes

function App() {
  return (
    <div className="App">
      <AppRoutes /> 
    </div>
  );
}

export default App;
