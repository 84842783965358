import React from 'react'
//composants
import Nav from '../../component/Nav'
import FooterDesk from "../../component/FooterDesk"
//Styles
import '../../Styles/ContactDesk.scss'
//Images
import Module1 from '../../Images/contact/Icone-contact-1.svg'
import Module2 from '../../Images/contact/Icone-contact-3.svg'
import Module3 from '../../Images/contact/Icone-contact-4.svg'
import Module4 from '../../Images/contact/Icone-contact-2.svg'

export default function Contactdesk() {
  return (
    <div className='All-contacdesk'>
        <Nav/>
        <div className='contact-module'>
            <div className='img-rotate1'>
              <img src={Module1} alt='img-Module1'/>
            </div>
            <div className='img-rotate2'>
              <img src={Module4} alt='img-Module1'/>
            </div>
            <div className='img-rotate3'>
              <img src={Module3} alt='img-Module1'/>
            </div>
            <div className='img-rotate4'>
              <img src={Module2} alt='img-Module1'/>
            </div>
        </div>
        <div className='contact-bouton'>
            <a href='/form-contact' alt='Formulaire-contact' target='_blanck'> <button>Contactez-nous !</button></a>
        </div>
        <div className='contact-footer'>
         <FooterDesk/>
        </div>
    </div>
  )
}
