const presentationtab = [
  {
    id: 1,
    
    images: [
      { src: require('../Images/Projet/NosSites/Arfdev/Accueilréseaux.webp'), desc: "Accueil" },
      { src: require('../Images/Projet/NosSites/Arfdev/Accueilaventures.webp'), desc: "Présentation 1" },
      { src: require('../Images/Projet/NosSites/Arfdev/Accueil.webp'), desc: "Présentation 2" },
      { src: require('../Images/Projet/NosSites/Arfdev/Projets.webp'), desc: "Présentation 3" },
      { src: require('../Images/Projet/NosSites/Arfdev/Screenshot.webp'), desc: "Présentation 4" },
      { src: require('../Images/Projet/NosSites/Arfdev/Skyblogjeu.webp'), desc: "Présentation 5" },
      { src: require('../Images/Projet/NosSites/Arfdev/Twitch.webp'), desc: "Présentation 6" },
    ],  
    titre: "Arf-dev.fr",
    texte: "Site Du dévellopeur collaborateur HoverTheTop",
    redirect: "https://arf-dev.fr/",

  },
  {
    id: 2,
    images: [
      { src: require('../Images/Projet/Template/Maquettevin.jpg'), desc: "Maquette vin" },
    ],
    titre: "Template HTTP",
    texte: "Un de nos premiers templates collaboratif",
    redirect: "https://www.figma.com/design/MaSQMQt8BmZOTXyQuT3QA1/Maquette---Vitrine---Vino-Grieco?node-id=0-1&t=HNWpOJnsOH0FXpVE-1",
  },
  {
    id: 3,
    images: [
      { src: require('../Images/Projet/Template/Maquettebiere.jpg'), desc: "Maquette bière" },
    ],
    titre: "Template HTTP",
    texte: "Un de nos premiers templates collaboratif",
    redirect: "https://www.figma.com/proto/Ea9ZJLRz9i6zS4tJTDJ3H7/Maquette---Vitrine---Ice-bottled-cube?node-id=1-2&starting-point-node-id=1%3A2&t=2rg49mKg9xasj87C-1",
  },
  {
    id: 4,
    images: [
      { src: require('../Images/Projet/NosSites/Emilien/Accueil.webp'), desc: "Accueil" },
      { src: require('../Images/Projet/NosSites/Emilien/Bio.webp'), desc: "Bio" },
      { src: require('../Images/Projet/NosSites/Emilien/Contact.webp'), desc: "Contact" },
      { src: require('../Images/Projet/NosSites/Emilien/Projet.webp'), desc: "Projet" },
    ],
    titre: "Eminence Graphique",
    texte: "Site du graphiste collaborateur HoverTheTop",
    redirect: "https://www.eminencegraphique.fr/",
  },
 
  // Ajoutez d'autres projets si nécessaire
];

export default presentationtab;
