import React from 'react'

// style 

import '../../Styles/ContactMobile.scss';

// Images
import FlecheVerte from '../../Images/contact/Fleches.svg'
import TraitVert from '../../Images/contact/Trait.svg'
import Module1 from '../../Images/contact/Icone-contact-1.svg'
import Module2 from '../../Images/contact/Icone-contact-3.svg'
import Module3 from '../../Images/contact/Icone-contact-4.svg'
import Module4 from '../../Images/contact/Icone-contact-2.svg'
import Bouton from '../../Images/contact/Bouttoncontact.svg'
import Nav from '../../component/Nav';


export default function ContactMobile() {
  return (
    <div className='All-contactMobile'>
      <Nav/>
      <div className='fleches'>
        <img src={FlecheVerte} alt='scroll' />
      </div>
       <div className='imgMobile'>
        <img src={Module1} alt="Module"></img>
      </div>
      <div className='trait'>
        <img src={TraitVert} alt='TraitVert' />
      </div>
      <div className='imgMobile'>
        <img src={Module4} alt="Module"></img>
      </div>
      <div className='trait'>
        <img src={TraitVert} alt='TraitVert' />
      </div>
      <div className='imgMobile'>
        <img src={Module3} alt="Module"></img>
      </div>
      <div className='trait'>
        <img src={TraitVert} alt='TraitVert' />
      </div>
      <div className='imgMobile'>
        <img src={Module2} alt="Module"></img>
      </div>
      <div className='trait'>
        <img src={TraitVert} alt='TraitVert' />
      </div>
      <div className='contact-bouton'>
            <a href='/form-contact' alt='Formulaire-contact' target='_blanck'> <img src={Bouton} alt='img-Module1'/></a>
        </div>

    </div>
  )
}
